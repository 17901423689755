<!-- View controlling the feature list for a specific ad. This is routed from ad-list.vue -->
<template>
  <v-card>
    <v-container v-if="authorized" fluid class="ma-0 pa-0" v-resize="onResize">
      <v-toolbar color="fill">
        <v-breadcrumbs :items="breadcrumbs">
          <template #item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" link>
              <span :class="!item.disabled ? 'primary--text' : ''">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <!-- <v-btn :to="'/ads'" :class="body" text class="accent--text text-capitalize">
          <v-icon left>mdi-chevron-left</v-icon>
          {{adParty.name}} ({{selectedAd.ad_name}})
        </v-btn> -->
        <v-spacer />
        <v-col cols="3" class="pl-0">
          <v-text-field :class="body" label="Filter Features" placeholder="Filter By Position or Text" class="mt-7"
            prepend-inner-icon="mdi-filter-outline" outlined dense background-color="input" v-model="search"
            @keydown.enter="getFilteredFeatures" clearable>
          </v-text-field>
        </v-col>
        <div class="text-center">
          <AdActions :ad="selectedAd" :adParty="adParty">
            <template v-slot:default="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
          </AdActions>
        </div>
        <!-- <v-btn text :class="body" color="primary" @click="component='FeatureInfo', modal=true">
          <v-icon left>mdi-plus</v-icon>Create
        </v-btn> -->
      </v-toolbar>
      <v-divider />
      <v-tabs background-color="white" grow height="35" hide-slider v-model="pageIndex">
        <v-tab v-for="(page, index) in pages" :key="index" @change="currentPage = page, getAdPageFeatures(page)"
          class="text-capitalize">
          {{ page.name }}
        </v-tab>
      </v-tabs>
      <v-data-table 
        :expanded.sync="expanded" 
        :footer-props="{ 'items-per-page-options': pageSizes }"
        :headers="featureHeaders" 
        :height="tableHeight" 
        :items-per-page="pageSize" 
        :items="adFeatures"
        :loading="loading" 
        style="cursor:pointer" 
        :class="table" 
        dense 
        fixed-header 
        item-key="id"
        loading-text="Loading Features...Please Wait" 
        no-data-text="No Features to Display"
        no-results-text="No Matching Features Found" 
        v-model="selected" 
        :sort-by="sortBy" 
        :sort-desc="false"
        @click:row="viewFeature">
        <template #[`item.promo_item_group_ids`]="{ item }">
          {{ item.promo_item_group_ids.length ? item.promo_item_group_ids.length : 0  }}
        </template>
        <template #[`item.non_promo_items`]="{ item }">
          {{ item.non_promo_items.length }}
        </template>
        <template #[`item.audit.updated_on`]="{ item }">
          {{ formatDateDisplay(item.audit.updated_on) }}
        </template>
        <!-- we are overriding the  'item' valeus  with the selectedAd values -->
        <template #[`item.ad_start_date`]="{}">
          {{ formatDateDisplay(selectedAd.ad_start_date) }}
        </template>
        <template #[`item.ad_end_date`]="{}">
          {{ formatDateDisplay(selectedAd.ad_end_date) }}
        </template>
        <template #[`item.classification`]="{}">
          {{ classification }}
        </template>
        <template #[`item.notes`]="{ item }">
          <v-btn small icon @click="selectedItem = item, component = 'Notes', modal = true, isAd = false">
            <v-icon>mdi-message-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-if="!authorized" justify="center" :value="!authorized" persistent max-width="400">
      <v-card color=#fafafa>
        <section class="ma-0 pa-6">
          <!-- <v-row justify="center">
        </v-row> -->
          <v-row justify="center" dense>
            <v-icon x-large color="red">mdi-alert-decagram</v-icon>
            <span class='pa-4' style='text-align:center' justify-content='center'>
              You do not have permission to view {{ selectedAd.ad_name }}. If you believe you are seeing this message
              in error, contact Support.
            </span>
          </v-row>
        </section>
      </v-card>
    </v-dialog>
    <FeatureView
      v-if="modal"
      :value.sync="modal"
      :selectedAd="selectedAd"
      :selectedFeature="selectedFeature"
      :pages="pages"
      :pagePositions="pagePositions"
      :currentPage="currentPage"
      :getAdPageFeatures="getAdPageFeatures"
      :adFeatures="adFeatures"
      :readonly="readonly"
      :adParty="adParty"
      :defaultParty="adParty"
      :parties="adGroups"
      :layouts="layouts"
      @refresh="getAdPageFeatures"
      @closeFeatureView="closeFeatureView"
    />
    <v-fab-transition v-if="!readonly">
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn v-on="on" :style="floatingButton" absolute bottom right fab class="primary mr-6 mb-12"
            @click="modal = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create Feature</span>
      </v-tooltip>
    </v-fab-transition>
  </v-card>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { userAccess } from '@/mixins/user-access'
import { format } from '@/mixins/format'
import { utils } from '@/mixins/utils'
import { isEmpty } from 'lodash'
export default {
  name: 'FeatureList',
  mixins: [displayHelpers, format, userAccess, utils],
  components: {
    AdActions: () => import('@/components/ads/ad-actions'),
    FeatureView: () => import('@/components/features/feature-view')
  },
  data() {
    return {
      ads: [],
      adParty: {},
      authorized: true,
      componentKey: 0,
      isExpanded: false,
      component: '',
      clipboard: {},
      expanded: [],
      fab: false,
      filtering: false,
      flag: false,
      isAd: false,
      layouts: [],
      loading: true,
      adFeatures: [],
      featurePromos: [],
      modal: false,
      currentPage: {},
      pagePositions: [],
      page: 1,
      pageSize: -1,
      pageSizes: [-1, 500, 1000],
      search: null,
      value: null,
      selected: [],
      selectedAd: {},
      selectedItem: {},
      selectedFeature: {},
      sortBy: 'position.display_order',
      pageIndex: 0,
      parties: [],
      tableHeight: '',
      defaultParty: {},
      disableNext: false,
      disablePrevious: true,
      offset: true,
      upstream: []
    }
  },
  computed: {
    adGroups() {
      if (this.limitAccessByRelatedAdGroups) {
        return this.$store.getters.adGroups.filter(adGroup => this.userAdGroupIds.includes(adGroup?.party_id))
      } else {
        return this.$store.getters.adGroups
      }
    },
    partyTypeName() {
      return this.adParty?.party_type?.constant
    },
    breadcrumbs() {
      return [
        {
          text: `${this.adParty.name} Ads`,
          exact: true,
          disabled: false,
          to: { name: 'Ad Planner' }
        },
        {
          text: this.classification + "  " + this.selectedAd.ad_name,
          exact: true,
          disabled: true
        },
        {
          text: this.selectedAd.ad_start_date + ' : ' + this.selectedAd.ad_end_date,
          exact: true,
          disabled: true
        },
      ]
    },
    readonly() {
      return this.selectedAd.status !== 'Draft' && this.selectedAd.status !== 'Rejected'
    },
    tableButtons() {
      return `
        height: 25px;
        width: 25px;
      `
    },
    floatingButton() {
      return `
        bottom: 25px;
        right: 5px; 
      `
    },
    isFiltered() {
      return !this._.isEmpty(this.search)
    },
    featureHeaders() {
      return [
        { sortable: false, class: 'accent white--text', width: '3%' },
        { text: 'Feature Position', align: 'left', sortable: true, filterable: false, value: 'position.name', class: 'accent white--text' },
        { text: 'Feature Text', align: 'left', sortable: true, filterable: false, value: 'feature_text', class: 'accent white--text' },
        { text: 'Promo Item Groups', align: 'center', sortable: true, filterable: false, value: 'promo_item_group_ids', class: 'accent white--text' },
        { text: 'Non-Promo Items', align: 'center', sortable: true, filterable: false, value: 'non_promo_items', class: 'accent white--text' },
        { text: 'Last Updated On', align: 'start', sortable: true, filterable: false, value: 'audit.updated_on', class: 'accent white--text' },
      ]
    },
    isFeatureSelected() {
      return !isEmpty(this.selectedFeature)
    },
    classification () {
      return (this.$store.getters.classifications?.length > 0) ? this.$store.getters.classifications.find(c => c.id === this.selectedAd.classification).constant : ''
    },
    pages () {
      return this.$store.getters.adPages
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.tableHeight = window.innerHeight - 223
          if (this.authorized) {
            this.getAdPages()
            this.isApproved()
          }
        }
      }
    },
    adParty: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem('ad_party', JSON.stringify(this.adParty))
        }
      },
      deep: true
    },
    selectedAd: {
      deep: true,
      handler() {
        localStorage.setItem('selected_ad', JSON.stringify(this.selectedAd))
      }
    },
    modal: {
      handler(value) {
        this.componentKey += 1
        if (!value) {
          this.selected = []
          this.selectedItem = {}
          this.selectedFeature = {}
          this.getAdPageFeatures(this.currentPage)
        }
      }
    },
    search: {
      handler() {
        if (!this.search) {
          this.filtering = false
          this.getAdPageFeatures(this.currentPage)
        }
      }
    }
  },
  async created() {
    this.tableHeight = window.innerHeight - 255
    // if (localStorage.selected_ad) {
    //   this.selectedAd = JSON.parse(localStorage.getItem('selected_ad'))
    // } else{
    if (this.$route.params.selectedAd) {
      this.selectedAd = this.$route.params.selectedAd
    } else {
      if (this.$route.query.id) {
        await this.getAd(this.$route.query.id)
      }
    }
    if (this.$route.params.party) {
      this.adParty = this.$route.params.party
    } else {
      this.adParty = JSON.parse(sessionStorage.getItem('ad_party'))
    }

    // If page thinks we're in a different ad group than the group this ad actually belongs to, reload the adgroup
    await this.verifySource()

    if (this.adGroups.length === 0) {
      await this.$store.dispatch('initParties')
    }
    this.getAdPages()
    this.getLayouts()
    this.$root.$on('refresh-features', () => this.getAdPageFeatures())
  },
  methods: {
    onResize() {
      this.tableHeight = window.innerHeight - 255
    },
    itemRowBackground(item) {
      return this.adFeatures.indexOf(item) % 2 !== 0 && !this.search ? 'grey lighten-2'
        : this.expanded.length && !this.search ? 'grey lighten-2'
          : this.expanded.length && this.search && this.filtering ? 'white' : ''
    },
    async getLayouts() {
      await this.$Layouts.getLayouts()
        .then(res => {
          this.layouts = res.data
        }).catch(err => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Layouts due to ${err?.response?.data?.message || err.message}` })
        })
    },
    async getAd(ad_id) {
      this.loading = true
      await this.$Ads.getAdById(ad_id).then(res => {
        if (res.data) {
          this.selectedAd = res.data
        }
      }).catch(err => {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Ad due to ${err?.response?.data?.message || err.message}` })
      }).finally(this.loading = false)
    },
    getAdPages(currentPage) {
      this.currentPage = this.pages.find(page => page.constant === currentPage ? currentPage.constant : 'PAGE_1')
      this.getAdPageFeatures(this.currentPage)
    },
    async verifySource() {
      try {
        if (this.selectedAd.ad_party_id !== this.adParty.party_id) {
          const res = await this.$Party.getPartyByID(this.selectedAd.ad_party_id)
          this.adParty = res.data
          this.adParty.party_id = this.adParty.id
          const dc_info = await this.getDistributionCenter(this.selectedAd.ad_party_id)
          this.adParty = { ...this.adParty, ...dc_info }
        }
      } catch(err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `${err?.response?.data?.message || err.message}` })
      }
    },
    async getAdPageFeatures() {
      const params = { limit: 300 }
      await this.$Features.getAdPageFeatures(this.selectedAd.id, this.currentPage.id, params)
        .then(res => {
          this.adFeatures = res.data.map(m => {
            m.ad_retail = parseFloat(m.ad_retail).toFixed(2)
            return m
          })
          this.adFeatures.classification = this.selectedAd.classification
          this.adFeatures.ad_start_date = this.selectedAd.ad_start_date
          this.adFeatures.ad_end_date = this.selectedAd.ad_end_date
          this.loading = false
        }).catch(err => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Page Positions due to ${err?.response?.data?.message || err.message}` })
        })
    },
    async getFilteredFeatures() {
      this.loading = true
      this.filtering = true
      this.adFeatures = []
      const requests = []
      const filteredResults = []
      this.pages.forEach(p => {
        requests.push(
          this.$Features.getAdPageFeatures(this.selectedAd.id, p.id)
            .then(res => {
              res.data.forEach(res => {
                if (this._.includes(res.position.name.toLowerCase(), this.search.toLowerCase()) || this._.includes(res.feature_text.toLowerCase(), this.search.toLowerCase())) {
                  filteredResults.push(res)
                }
              })
            })
        )
      })
      await Promise.allSettled(requests)
      this.adFeatures = filteredResults
      this.loading = false
    },
    async checkParties() {
      const email = this.$auth.fullToken.email
      const params = { party_email: email, party_type: 'USER' }
      await this.$PartyRelationship.getUpstreamRelationshipsUser(params).then(res => {
        this.upstream = res.data.related
      }).catch(err => {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Parties due to ${err?.response?.data?.message || err.message}` })
      })
      if (this.upstream.includes(this.selectedAd.ad_party_id)) {
        this.authorized = true
      }
      else {
        this.authorized = false
      }
    },
    async updateAdFeature(selectedFeature) {
      let payload = selectedFeature
      await this.$Features.updateAdFeature(payload.id, payload).then(() => {
        this.$store.dispatch('setSnackbar', { status: 'success', text: `Updated ${payload.position.name}` })
      }).catch(err => {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Update Feature due to ${err?.response?.data?.message || err.message}` })
      })
    },
    customFilter(items, search, filter) {
      search = search.toString().toLowerCase()
      return items.filter(row => filter(row["type"], search));
    },
    viewFeature(feature) {
      this.selectedFeature = feature
      this.component = 'FeatureView'
      this.modal = true
    },
    isApproved() {
      this.selectedAd.status !== 'Approved' ? this.flag = true : this.flag = false;
    },
    collapseAll() {
      this.$data.expanded = []
    },
    expandAll() {
      this.$data.expanded = this.adFeatures
    },
    closeFeatureView() {
      this.modal = false
    }
  }
}
</script>

<style scoped>
.active {
  box-shadow: 0px 2px 10px 3px rgba(120, 120, 120, 0.75);
  background: #385F73;
  color: white !important;
}

.filtered {
  background: #FFC107 !important;
  color: white !important;
}
</style>
